import(/* webpackMode: "eager", webpackExports: ["SanityAssetImage"] */ "/vercel/path0/apps/netthandel/src/cms/images/SanityAssetImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Slideshow"] */ "/vercel/path0/apps/netthandel/src/components/Slideshow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionSection"] */ "/vercel/path0/apps/netthandel/src/sections/AccordionSection/AccordionSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Feedback"] */ "/vercel/path0/apps/netthandel/src/sections/FeedbackSection/Feedback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductCarousel"] */ "/vercel/path0/apps/netthandel/src/sections/ProductCarouselSection/ProductCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubscriptionCampaignFocus"] */ "/vercel/path0/apps/netthandel/src/sections/SubscriptionCampaignFocusSection/SubscriptionCampaignFocus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/tracking/CustomTracking/ComponentViewTracker.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Carousel/useCarousel.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Modal/Modal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Table/TableRows.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Tabs/Tabs.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Tooltip/Tooltip.js");
